<template>
  <div class="referrals">
    <div class="filters">
      <div class="search">
        <label for="search">Cerca</label>
        <input id="search" type="text" name="" placeholder="Nome, Utente CVing" @input="filterSearch($event)">
      </div>

      <div class="date-box">
        <label for="date-from">Data inizio</label>
        <input id="date-from" type="date" @input="dateFromChanged($event)">
      </div>

      <div class="date-box">
        <label for="date-to">Data fine</label>
        <input id="date-to" type="date" @input="dateToChanged($event)">
      </div>

      <div class="lens">
        <font-awesome-icon icon="magnifying-glass" class="fa-lg" />
      </div>
    </div>
    <div class="table">
      <table cellspacing="0">
        <thead>
          <tr>
            <th v-for="header of headers" :key="header" class="orderable" @click="changeOrder(header)">
              <div class="">
                <span>{{ header }}</span>
                <font-awesome-icon v-if="filters.order.field == header" :icon="filters.order.asc ? 'arrow-down-a-z' : 'arrow-down-z-a'" />
              </div>
            </th>
            <th style="width: 50px" />
          </tr>
        </thead>
        <tbody class="table-body">
          <slot v-for="user in suggestors" :key="user.email">
            <tr class="cursor-pointer" @click="toggleMasterDetail(user.email)">
              <td><span>{{ user.name }}</span></td>
              <td><span>{{ user.lastname }}</span></td>
              <td><span>{{ user.email }}</span></td>
              <td><span>{{ user.suggested }}</span></td>
              <td><span>{{ user.candidated }}</span></td>
              <td><span>{{ user.unlocked }}</span></td>

              <td class="chevron" style="width: 50px">
                <svg :class="{ rotate: collapsiblePanelOpened === user.email }" style="width: 24px; height: 24px" viewBox="0 0 24 24">
                  <path fill="currentColor" d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z" />
                </svg>
              </td>
            </tr>

            <tr v-if="collapsiblePanelOpened === user.email" class="collapsible-content">
              <td colspan="7" class="content-inner" style="padding: 0 !important">
                <div class="table-container">
                  <table class="content-table history">
                    <tr class="history-tr-header">
                      <th @click="changeOrderInside('')">
                        <span>Nome suggerito</span>
                        <font-awesome-icon v-if="filters.order.fieldInside == ''" :icon="filters.order.ascInside ? 'arrow-down-1-9' : 'arrow-down-9-1'" />
                      </th>
                      <th @click="changeOrderInside('')">
                        <span>Cognome suggerito</span>
                        <font-awesome-icon v-if="filters.order.fieldInside == ''" :icon="filters.order.ascInside ? 'arrow-down-1-9' : 'arrow-down-9-1'" />
                      </th>
                      <th @click="changeOrderInside('')">
                        <span>Email suggerito</span>
                        <font-awesome-icon v-if="filters.order.fieldInside == ''" :icon="filters.order.ascInside ? 'arrow-down-a-z' : 'arrow-down-a-z'" />
                      </th>
                      <th @click="changeOrderInside('')">
                        <span>Posizione</span>
                        <font-awesome-icon v-if="filters.order.fieldInside == ''" :icon="filters.order.ascInside ? 'arrow-down-1-9' : 'arrow-down-9-1'" />
                      </th>
                      <th @click="changeOrderInside('')">
                        <span>Azienda</span>
                        <font-awesome-icon v-if="filters.order.fieldInside == ''" :icon="filters.order.ascInside ? 'arrow-down-a-z' : 'arrow-down-z-a'" />
                      </th>
                      <th @click="changeOrderInside('')">
                        <span>Data e ora suggerimento</span>
                        <font-awesome-icon v-if="filters.order.fieldInside == ''" :icon="filters.order.ascInside ? 'arrow-down-a-z' : 'arrow-down-z-a'" />
                      </th>
                    </tr>
                    <tr
                      v-for="r in referred"
                      :key="r.email"
                      class="history-tr-content"
                    >
                      <td><span>{{ r.name }}</span></td>
                      <td><span>{{ r.lastname }}</span></td>
                      <td><span>{{ r.email }}</span></td>
                      <td><span>{{ r.position }}</span></td>
                      <td><span>{{ r.company }}</span></td>
                      <td><span>{{ r.date }}</span></td>
                    </tr>
                  </table>
                </div>
              </td>
            </tr>
          </slot>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script setup>
import { spinnerStore } from '@/stores/spinner'
import { navbarStore } from '@/stores/navbar'
import { reactive } from '@vue/reactivity'
import { onMounted } from '@vue/runtime-core'
import { ref } from 'vue'

const suggestors = reactive([])
const referred = reactive([])

const filters = reactive({
  order: {
    field: 'Data',
    asc: false
  },
  search: [],
  dateFrom: null,
  dateTo: null
})
const headers = ['Nome', 'Cognome', 'Email', 'Suggeriti', 'Candidati', 'Sbloccati']
const spinner = spinnerStore()
const navbar = navbarStore()

const collapsiblePanelOpened = ref('')
const toggleMasterDetail = (email) => {
  if (collapsiblePanelOpened.value === email) collapsiblePanelOpened.value = null
  else collapsiblePanelOpened.value = email
}

/**
 * Set the order condition for the table
 * @param {number} orderBy - the column to order by
 */
const changeOrder = (orderBy) => {
  if (filters.order.field == orderBy) {
    filters.order.asc = !filters.order.asc
  } else {
    filters.order.field = orderBy
    filters.order.asc = true
  }
}

/**
		 * manage the search input
		 * @param {Event} event - the oninput input event
		 */
const filterSearch = ({ target }) => {
  filters.search = target.value ? target.value.split(' ') : []
}

onMounted(() => {
  navbar.title = 'Suggeritori'
  spinner.show()
  suggestors.push({
    name: 'Mario',
    lastname: 'Rossi',
    email: 'mario.rossi@gmail.com',
    suggested: 12,
    candidated: 5,
    unlocked: 3
  })
  referred.push({
    name: 'Marco',
    lastname: 'Rossi',
    email: 'marco.rossi@gmail.com',
    position: 'CEO',
    company: 'Google',
    date: '12/12/2020 12:12'
  })
  spinner.hide()
})
</script>

<style lang="scss" scoped>
@use '../assets/scss/referrals';
@use '../assets/scss/table';
.referrals table thead,
.referrals table thead tr:first-child,
.referrals table thead tr:first-child th{
  border-bottom-left-radius: 0px!important;
  border-bottom-right-radius: 0px!important;
}
.referrals table tr th:first-child,
.referrals table .table-body tr td:first-child{
  padding-left:1.5rem!important;
}
.text-xs{
  font-size:13px;
  margin-top:4px;
}

table {
  th {
    &:nth-child(1) {
      width: 100px;
    }
  }

  td.chevron svg {
	transform: rotate(0deg);
	transition: all 50ms ease-in;
	&.rotate {
		transform: rotate(90deg);
		transition: all 100ms ease-in;
	}
}
}

.collapsible-content {
	max-height: 0px;
	overflow: hidden;
	transition: max-height 0.25s ease-in-out;
}

.toggle:checked + .lbl-toggle + .collapsible-content {
	max-height: 100vh;
}

.table-container {
	border-radius: 5px;
	margin: 10px 50px 55px 50px;
	box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
	overflow: hidden;
}

.collapsible-content .content-inner {
	border-bottom: 1px solid #00dbc350;
	border-top: 1px solid #00dbc350;
	padding: 0.5rem 1rem;
}

.content-table {
	border-radius: 5px;
	margin: 1px;
	background: #1a73a347;
	tr:first-child {
		background: #1a74a3;
	}
	tr th:first-child,
	tr td:first-child {
		text-align: left;
		//width: 20%;
		// max-width: 20%;
		padding-left: 30px;
	}
	tr th,
	tr td {
		text-align: center;
		padding-left: 30px;
		width: calc(100% / 5);
		max-width: calc(100% / 5);
		// border:1px solid #dc3545;
	}
	.badge-euro {
		display: inline-block;
		border: 1px solid #1f88c1;
		background-color: #0cced2c2;
		// background: transparent;
		color: white;
		min-width: 35px;
		height: 35px;
		border-radius: 35px;
		text-align: center;
		line-height: 35px;
		margin: 0 5px;
		font-weight: 600;
		padding: 0 10px;
	}
	.badge-payment {
		display: inline-block;
		border: 1px solid #db5832;
		background: transparent;
		//background-image: linear-gradient(to right, transparent, #db583235, #db583245, #db583248, #db583245, #db583235, transparent);
		color: white;
		min-width: 35px;
		height: 35px;
		border-radius: 5px;
		text-align: center;
		line-height: 35px;
		margin: 0 5px;
		font-weight: 600;
		padding: 0 10px;
		text-transform: uppercase;
		line-height: 35px;
		font-size: 12px;
		&.paid {
			border: 1px solid #1f88c1;
			//background: #8dbc68;
			background: transparent;
			//background-image: linear-gradient(to right, transparent, #1f88c135, #1f88c145, #1f88c148, #1f88c145, #1f88c135, transparent);
		}
	}
}
</style>
